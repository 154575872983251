@import '@/styles/common.scss';

.demo-modelForm {
    height: 600px;
    overflow-y: auto;
}

.form-submit {
    text-align: right;
    position: absolute;
    bottom: 0px;
    margin-top: 11px;
    margin-bottom: 11px;
    width: calc(100% - 30px);
}

/deep/.demo-modelForm .el-form-item__label {
    width: 100% !important;
    text-align: left;
    padding: 0 5px;
}

/deep/.demo-modelForm .el-form-item__content {
    margin-left: 0 !important;
}

a {
    text-decoration: none;
    color: #121111;
}

.el-table ::-webkit-scrollbar {
    width: 0px;
    /*滚动条宽度*/
}
